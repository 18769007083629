import React from 'react';
import cx from 'classnames';
import styles from './Expandable.module.scss';

import projects from '../../projects.json'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMore from '@material-ui/icons/ExpandMore';

export type ExpandableProps = {
    className?: string;
};

const Expandable: React.FC<ExpandableProps> = ({
    className
}) => {
    const projectAccordians = projects?.map((project, index) => (
        <Accordion key={index} className={styles.expandable} square={false} sx={{marginBottom: "20px"}}>
            <AccordionSummary
                expandIcon={<ExpandMore className={styles.expandIcon}/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={styles.summaryText}>{project.name}</Typography>
            </AccordionSummary>
            <AccordionDetails  sx={{
                backgroundColor: "black",
                opacity: "30%",
                zIndex: "-20",
                borderBottomLeftRadius: "25px",
                borderBottomRightRadius: "25px",
            }}>
                    <div className={styles.detailText}>
                        <ul>
                            {project.description.map((desc, index) => <li key={index}>{desc}</li>)}
                        </ul>
                        <div className={styles.techBox}>
                            {project.techs.map((tech, index) => <span key={index} className={styles.techText}>{tech}</span>)}
                        </div>
                    </div>
            </AccordionDetails>
        </Accordion>
    ));

    return (
        <div className={cx(styles.expandable, className)}>{projectAccordians}</div>
    );
};

export default Expandable;
