import React from 'react';
import styles from "../SlideContent/SlideContent.module.scss";
import procurifyLogo from "../../logos/procurify-logo.svg"
import RTILogo from "../../logos/redthread-logo.svg"
import SAPlogo from "../../logos/sap-logo.svg"


export type Experience = {
    name: String,
    description: String[],
    tech: String[],
    year: Number,
    startMonth: String,
    endMonth?: String,
    img: string,
}


export type SlideContentProps = {
    experience: Experience
};

let logos = new Map([
    ["sap", SAPlogo],
    ["procurify", procurifyLogo],
    ["rti", RTILogo]
])

const SlideContent: React.FC<SlideContentProps> = ({experience: {name, description, tech, year, startMonth, endMonth, img}}) => {
    return (
            <div className={styles.slideContent}>
                <div style={{display: "flex", height: "95%"}}>
                    <div className={styles.verticalText}>
                        {String(year)} <span className={styles.months}> {`${startMonth}  - ${endMonth === undefined ? "Present" : endMonth}`} </span>
                    </div>
                    <div className={styles.jobContainer}>
                        <div className={styles.jobTitle} style={{backgroundImage: `url(${logos.get(img)})`}}>
                            <p>
                                {name} </p>
                        </div>
                        <div className={styles.jobContent}>
                            <ul className={styles.descText}>
                                {description.map((d, index) => (
                                    <li key={index}>{d}</li>
                                ))}
                            </ul>
                                <div className={styles.techBox}>
                                    {tech.map((t, index) => <span key={index} className={styles.techText}>{t}</span>)}
                                </div>
                        </div>
                    </div>
                </div>
            </div>
    );
};

export default SlideContent;
