import React from 'react';
import cx from 'classnames';
import styles from './InformationBlock.module.scss';
import Expandable from "../Expandable";

import ubc from "../../logos/ubc.png";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMore from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

export type InformationBlockProps = {
    className?: string;
};

const personalText = "Hi! If you don't remember already, my name is Eric Lau and I am a fourth year computer science student. Though coding has a special place in my heart (take a 👀 at some of my projects on this page!), in my free-time, I enjoy spending it outdoors, particularly by going on a scenic bike ride or hike. This is just a brief summary of who I am, so if you have any questions, feel free to reach out!"

const InformationBlock: React.FC<InformationBlockProps> = ({
                                                               className
                                                           }) => {
    const [expanded, setExpanded] = React.useState<string | false>('aboutMe');

    const handleChange = (panel: string) => (
        event: React.SyntheticEvent,
        isExpanded: boolean
    ) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <section id="about" className="section">
            <div style={{display: "flex", justifyContent: "flex-start"}}>
                <p className={"title"}>About Me</p>
            </div>
            <div className={styles.informationBlock}>
                <div className={styles.aboutContainer}>
                    <div className={styles.aboutGrid}>
                        <div className={cx(styles.title, styles.personal)}>
                            <p>
                                Personal
                            </p>
                        </div>
                        <div className={styles.personalBlock}>
                            <p>{personalText}</p>
                        </div>
                        <div className={cx(styles.title, styles.education)}>
                            <p>Education</p>
                        </div>
                        <div className={styles.educationBlock}>
                            <img className={styles.ubcLogo} src={ubc}/>
                        </div>
                        <div className={cx(styles.title, styles.projects)}>
                            <p>
                                Projects
                            </p>
                        </div>
                        <div className={styles.projectsBlock}>
                            <Expandable/>
                        </div>
                    </div>
                </div>

                <div className={styles.informationBlockMobile}>
                    <Accordion
                        className={styles.expandable}
                        square={false}
                        expanded={expanded === "aboutMe"}
                        onChange={handleChange("aboutMe")}>
                        <AccordionSummary
                            expandIcon={<ExpandMore className={styles.expandIcon}/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={styles.title}>
                                Personal
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={styles.details}>
                            <Typography className={styles.detailsText}>
                                {personalText}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        className={styles.expandable}
                        square={false}
                        expanded={expanded === "education"}
                        onChange={handleChange("education")}>
                        <AccordionSummary
                            expandIcon={<ExpandMore className={styles.expandIcon}/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={styles.title}>
                                Education
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={styles.details}>
                            <img className={styles.ubcLogo} src={ubc}/>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        className={styles.expandable}
                        square={false}
                        expanded={expanded === "projects"}
                        onChange={handleChange("projects")}>
                        <AccordionSummary
                            expandIcon={<ExpandMore className={styles.expandIcon}/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={styles.title}>
                                Projects
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={styles.details}>
                            <Expandable/>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>

        </section>
    )
};

export default InformationBlock;
