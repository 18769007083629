import React from 'react';
import NavigationPage from "./Components/NavigationPage";
import SlideContent from "./Components/SlideContent";
import InformationBlock from "./Components/InformationBlock";
import Div100vh from 'react-div-100vh'

import experience from './experience.json';
import Lottie from "lottie-react";
import homep from './homep.json';
import './App.scss';

// Import Swiper React components
import {Swiper, SwiperSlide} from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import {Navigation, Mousewheel, Keyboard, Scrollbar, Pagination} from "swiper";
import Accordion from "@mui/material/Accordion";
import styles from "./Components/Accordion/Expandable.module.scss";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";

function App() {
    return (
        <div className="App">
            <NavigationPage/>
            <section id="home" className="section">
                <Div100vh>
                <Lottie style={{height: "80vh"}} animationData={homep}/>
                <p style={{fontFamily: "Akira Expanded"}}>
                    Welcome to my portfolio
                </p>
                </Div100vh>
            </section>
            <section id="work" className="section">
                <Div100vh>
                <div style={{display: "flex", justifyContent: "flex-start"}}>
                    <p className={"title"}>Work Experience</p>
                </div>
                <Swiper
                    pagination={{
                        clickable: true,
                    }}
                    mousewheel={{
                        forceToAxis: true,
                        sensitivity: 1,
                    }}
                    slidesPerView={1}
                    keyboard={true}
                    modules={[Mousewheel, Keyboard, Pagination]}
                >
                    {experience.map((exp) => (
                        <SwiperSlide>
                            <SlideContent experience={exp}/>
                        </SwiperSlide>
                    ))}
                </Swiper>
                </Div100vh>
            </section>
            <Div100vh>
                <InformationBlock/>
            </Div100vh>
        </div>
    );
}

export default App;
