import React from 'react';
import Modal from '@mui/material/Modal';
import IconButton from "@mui/material/IconButton";
import cx from "classnames";
import styles from './NavigationPage.module.scss';

import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import EmailIcon from '@material-ui/icons/Email';


export type NavigationPageProps = {
    className?: string;
};

const NavigationPage: React.FC<NavigationPageProps> = ({
    className
}) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
            <div className={styles.navigationPage}>
                <div className={styles.nav}>
                    <div>
                        <a href="#home" className={styles.navItem}>home</a>
                        <a href="#work" className={styles.navItem}>work</a>
                        <a href="#about" className={styles.navItem}>about me</a>
                    </div>
                    <div className={styles.contact}>
                        <a href="mailto:ericlauuu@gmail.com" className={styles.navItem} target="_blank">
                            <EmailIcon className={cx(styles.socialIcon)}/>
                        </a>
                        <a href="https://ca.linkedin.com/in/eric-lau-854255122" className={styles.navItem} target="_blank">
                            <LinkedInIcon className={cx(styles.socialIcon, styles.linkedIn)}/>
                        </a>
                    </div>
                </div>

                <div className={styles.button}>
                    <IconButton onClick={handleOpen}>
                        <MenuIcon style={{fill: 'white'}}/>
                    </IconButton>
                </div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    closeAfterTransition
                    sx={{background: 'black'}}
                    className={styles.modal}
                >
                    <div className={styles.navigationPage}>
                        <div className={styles.button}>
                            <IconButton onClick={handleClose}>
                                <CloseIcon style={{fill: 'white'}}/>
                            </IconButton>
                        </div>
                        <div className={styles.menuAndContacts}>
                        <ul className={styles.menu}>
                            <li><a onClick={handleClose} href="#home" className={styles.navItem}>home</a></li>
                            <li><a onClick={handleClose} href="#work" className={styles.navItem}>work</a></li>
                            <li><a onClick={handleClose} href="#about" className={styles.navItem}>about me</a></li>
                        </ul>
                            <div className={styles.contact}>
                                <a href="mailto:ericlauuu@gmail.com" className={styles.navItem} target="_blank">
                                    <EmailIcon className={cx(styles.socialIcon)}/>
                                </a>
                                <a href="https://ca.linkedin.com/in/eric-lau-854255122" className={styles.navItem} target="_blank">
                                    <LinkedInIcon className={cx(styles.socialIcon, styles.linkedIn)}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
)
};

export default NavigationPage;
